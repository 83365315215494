import Vue from 'vue';
import { sendMessage, getAddressProperty } from '@/_utils';
import { IConfigAppointmentModule, IAppointment } from '@/_store/types';
import { namespace } from 'vuex-class';
import { APPOINTMENT_NAMESPACE } from '@/_store/appointment';
import { Component } from 'vue-property-decorator';
import { IProperty } from '@/model/property.model';
import moment from 'moment';

const appointmentModule = namespace(APPOINTMENT_NAMESPACE);

@Component({})
export default class ConfirmAppointment extends Vue {

  @appointmentModule.Getter getConfig!: IConfigAppointmentModule;
  @appointmentModule.Getter getProperty!: IProperty;
  @appointmentModule.Getter getAppointmentByID!: (id: string) => IAppointment;
  @appointmentModule.Getter getAppointmentClicked!: number;

  public mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  get logoUrl() {
    return this.getConfig.logo || '';
  }

  get buttonBackColor() {
    return '#' + this.getConfig.buttonBackColor || '';
  }

  get buttonFontColor() {
    return '#' + this.getConfig.buttonFontColor || '';
  }

  get propertyAddress() {
    if (this.getProperty) {
      const glue = this.$t('ATLOCATION') as string;
      return getAddressProperty(this.getProperty,glue);
    }
    return '';
  }

  get appointment() {
    return this.getAppointmentByID(this.getAppointmentClicked.toString()) || null;
  }

  get getConfirmText() {
    const str = this.$t('CONTACT_APPOINTMENT_FEEDBACK') as string;
    if (this.appointment) {
      return str.replace('{PROPERTY_ADDRESS}', `<b>${this.propertyAddress as string}</b>`)
      .replace('{APPOINTMENT_DATE}', `<b>${moment(this.appointment.StartTime).locale(this.$i18n.locale).format('dddd D MMMM YYYY')}</b>`)
      .replace('{APPOINTMENT_TIME}', `<b>${moment(this.appointment.StartTime).locale(this.$i18n.locale).format('HH:mm')}</b>`)
    } else {
      return this.$t('CONTACT_APPOINTMENT_FEEDBACK_WAITING');
    }
  }

  get getConfirmText2() {
    const str = this.$t('CONTACT_APPOINTMENT_FEEDBACK_2') as string;
    return str;
  }

  public handleBackToCalendar() {
    sendMessage({ type: 'hide-modal', mess: 'hide-modal' });
  }
}
